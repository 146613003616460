<template>
  <div
    :class="title.class"
    @click="toggleCustomerSupportChat"
    :style="title.style"
  >
    <span id="tooltip-target-1"> {{ checkConnectedUser }} </span>
    <b-tooltip
      target="tooltip-target-1"
      triggers="hover"
      v-if="connectedUser.length > 1"
    >
      <div v-for="user in connectedUser" :key="user.id" class="d-flex w-100">
        {{ user.name + " " + user.lastName }}
      </div>
    </b-tooltip>
    <img :src="require('@/assets/images/widgets/cusService.svg')" alt="" />
    <div
      :class="button.class"
      :style="button.closeButton.style"
      v-if="customerSupportChat"
    >
      <x-circle-icon size="1.5x"></x-circle-icon>
    </div>
    <div
      :class="button.class"
      :style="button.backButton.style"
      @click.stop="backChat()"
      v-if="customerSupportChat"
    >
      <arrow-left-circle-icon size="1.5x"></arrow-left-circle-icon>
    </div>
  </div>
</template>
<script>
import { XCircleIcon, ArrowLeftCircleIcon } from "vue-feather-icons";
import { BTooltip } from "bootstrap-vue";
import { mapMutations, mapGetters } from "vuex";
import store from "@/store";
export default {
  data() {
    return {
      title: {
        class: "d-flex justify-start font-weight-bold cursor-pointer",
        style:
          "padding: 10px;color: black;background: linear-gradient(51deg, #00ff01 55%, #00fffb);justify-content: space-between;border-radius: 10px 30px 0px 0px;",
      },
      button: {
        class: "rounded-circle",
        closeButton: {
          style:
            "padding: 2px;display: flex;position: absolute;right: -29px;background-color: #00ffb5;",
        },
        backButton: {
          style:
            " padding: 2px;display: flex;position: absolute;right: -60px;background-color: rgb(0, 255, 181);",
        },
      },
    };
  },
  methods: {
    ...mapMutations("customerSupportStore", {
      setCustomerSupportChat: "setCustomerSupportChat",
    }),
    toggleCustomerSupportChat() {
      this.setCustomerSupportChat(!this.customerSupportChat);
    },
    backChat() {
      store.commit("customerSupportStore/resetChat");
      store.commit("signalRStore/resetTicket");
    },
  },
  computed: {
    checkConnectedUser() {
      if (this.selectedTicket.data) {
        const user = this.connectedNameList.find(
          (connected) =>
            Number(connected.id) ==
            Number(this.selectedTicket.data.responderIDs[0])
        );
        if (user) {
          return `${user.name} ${user.lastName} Bağlandı`;
        } else {
          return "Canlı Desteğe Bağlan";
        }
      } else {
        return "Canlı Desteğe Bağlan";
      }
    },
    ...mapGetters("signalRStore", {
      selectedTicket: "getTicket",
    }),
    ...mapGetters("customerSupportStore", {
      customerSupportChat: "getCustomerSupportChat",
      connectedUser: "getCustomerConnectedUser",
      connectionStart: "getConnectionStart",
      connectedNameList: "getConnectedNameList",
    }),
  },
  components: {
    XCircleIcon,
    ArrowLeftCircleIcon,
    BTooltip,
  },
};
</script>
