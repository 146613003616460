<template>
  <div
    class="d-flex flex-column h-100 py-2 px-1"
    style="justify-content: space-between"
    v-if="ticketList && !connectionStart && !startNewChat && !checkRating"
  >
    <div>
      <p class="text-center">
        Canlı Desteğe Hoş Geldiniz<br /><br />
        Aktif olan bir destek talebine bağlanabilir veya yeni bir destek talebi
        oluşturabilirsiniz.
      </p>
    </div>
    <!-- Önceki Hali-->
    <!-- <b-form-select v-model="selectTicket" class="mb-1 mt-1">
      <b-form-select-option :value="null">
        Bir Destek Talebi Seç
      </b-form-select-option>
      <b-form-select-option
        v-for="(ticket, index) in ticketList"
        :key="index"
        :value="ticket"
      >
        {{ ticket.subject }}
      </b-form-select-option>
    </b-form-select>

    <b-button variant="success" @click="connectSelectedTicket"
      >Seçili Destek Talebine Bağlan</b-button
    > -->

    <div class="ticketList" style="" v-if="ticketList.length > 0">
      <div
        v-for="(ticket, index) in ticketList"
        :key="index"
        class="ticketDiv"
        :style="getColor(ticket.priority)"
        @click="connectSelectedTicket(ticket)"
      >
        <div
          style="
            display: flex;
            width: 100%;
            justify-content: space-between;
            font-size: 10px;
          "
        >
          <span>
            {{ ticket.readableID }}
          </span>
          <span>
            {{ getDate(ticket.createdDate) }}
          </span>
        </div>
        <div style="font-weight: 600" class="inDiv">
          {{ ticket.subject }}
        </div>
        <div>
          {{ ticket.description }}
        </div>
      </div>
    </div>
    <div v-else class="text-center">
      <p>Eski Destek Talebi Bulunmuyor</p>
    </div>
    <b-button variant="outline-success" @click="startNewChatFunc"
      >Yeni Bir Destek Talebi Oluştur</b-button
    >
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import store from "@/store";
import { BButton, BFormSelect, BToast, BFormSelectOption } from "bootstrap-vue";
export default {
  computed: {
    getColor() {
      return (priority) => {
        switch (priority) {
          case 1:
            return "background-color:#b9ffac33";
          case 2:
            return "background-color:#eeff8b33";
          case 3:
            return "background-color:#ffc28b33";
          case 4:
            return "background-color:#ff373733";
          default:
            return "background-color:white";
        }
      };
    },
    ...mapGetters("customerSupportStore", {
      ticketList: "getTicketList",
      connectionStart: "getConnectionStart",
      startNewChat: "getStartNewChat",
    }),
    ...mapGetters("signalRStore", {
      ticket: "getTicket",
    }),
    selectTicket: {
      get() {
        return store.getters["customerSupportStore/getSelectTicket"];
      },
      set(newValue) {
        store.commit("customerSupportStore/setSelectTicket", newValue);
      },
    },
    checkRating() {
      if (this.ticket && this.ticket.data) {
        return this.ticket.data.status == 4 ? true : false;
      } else {
        return false;
      }
    },
    getDate() {
      return (dates) => {
        const date = new Date(dates);

        const day = String(date.getUTCDate()).padStart(2, "0");
        const month = String(date.getUTCMonth() + 1).padStart(2, "0");

        return `${day}/${month}`;
      };
    },
  },
  components: {
    BFormSelect,
    BButton,
    BFormSelectOption,
    BToast,
  },
  methods: {
    async connectSelectedTicket(ticket) {
      await store.dispatch("signalRStore/connectSelectedTicket", ticket);
      store.commit("customerSupportStore/setConnectionStart", true);
      store.commit("signalRStore/joinGroup");
      store.dispatch("signalRStore/fetchAllMessage");
    },
    startNewChatFunc() {
      store.commit("customerSupportStore/setStartNewChat", true);
    },
  },
};
</script>
<style>
.ticketList::-webkit-scrollbar {
  width: 5px;
}

.ticketList::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Scrollbar izleme alanının arka plan rengi */
}

.ticketList::-webkit-scrollbar-thumb {
  background-color: #229000; /* Scrollbar başparmak kısmının rengi */
  border-radius: 4px; /* Scrollbar başparmak kısmının köşe yuvarlaklığı */
  z-index: 150;
}

.ticketList::-webkit-scrollbar-thumb:hover {
  background-color: #33ff00; /* Scrollbar başparmak kısmının hover rengi */
}
.ticketList {
  padding-top: 10px;
  height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.ticketDiv > .inDiv {
  border-bottom: 1px solid rgba(0, 0, 0, 0.199); /* İstediğiniz border stilini buraya yazabilirsiniz */
  padding-bottom: 7px;
}
.ticketDiv > div:last-child {
  padding-top: 7px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ticketDiv {
  user-select: none;
  cursor: pointer;
  margin-left: 5px;
  box-shadow: 0px 0px 6px #00000024;
  margin-right: 10px;
  background-color: #d8d8d833;
  padding: 10px 10px;
  margin-bottom: 22px;
  border-radius: 5px;
  word-break: break-word;
}
</style>