<template>
  <div class="chatWindow">
    <div class="chatTitle">
      <div>
        {{ chatContent[chatType].title }}
      </div>
      <div style="cursor: pointer" @click="closeChat">X</div>
    </div>
    <div class="chatContent" v-if="!success">
      <div class="subtitle">
        {{ chatContent[chatType].subTitle }}
      </div>
      <div class="chatInput text-center">
        {{ getUser.musteriAd.trim() }}
      </div>
      <div class="chatInput">
        <b-form-input
          placeholder="Konu"
          v-model="about"
          type="text"
        ></b-form-input>
      </div>
      <div class="chatInput">
        <b-form-textarea
          id="textarea-rows"
          :placeholder="chatContent[chatType].textAreaTitle"
          rows="4"
          v-model="description"
          no-resize
          autofocus
        ></b-form-textarea>
      </div>
      <div>
        <button @click="send" class="w-75 mb-1">
          {{ chatContent[chatType].buttonText }}
        </button>
      </div>
    </div>
    <div v-else class="successDiv">
      <p>
        Öneriniz/Şikayetiniz başarıyla gönderildi. Geri bildiriminiz için
        teşekkür ederiz. En kısa sürede sizinle iletişime geçeceğiz.
      </p>
      <button @click="closeSuccess" class="w-75 mb-1">Kapat</button>
    </div>
  </div>
</template>
<style scoped>
.successDiv {
  height: 100%;
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  /* align-content: center; */
  align-items: center;
  justify-content: center;
}
.chatInput {
  display: inline-flex;
  flex-direction: column;
  padding: 0px 20px;
  text-align: left;
}

.subtitle {
  font-size: 12px;
}
.chatContent {
  display: flex;
  height: 100%;
  padding: 5px 5px 0px 5px;
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
}
.chatWindow {
  display: flex;
  width: 273px;
  height: 377px;
  background-color: white;
  flex-direction: column;
  border-radius: 10px 10px 0px 0px;
  box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 24%) !important;
  position: fixed;
  z-index: 999;
  bottom: 0px;
  right: 60px;
  overflow: hidden;
}
.chatTitle {
  user-select: none;
  background-color: #f9ec01;
  display: inline-flex;
  width: 100%;
  font-weight: 1000;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.chatWindow button {
  height: 33px;
  border: 0px;
  background-color: #f9ec01;
  border-radius: 10px;
  font-weight: bold;
}
</style>
<script>
import { BFormTextarea, BFormInput, BToast } from "bootstrap-vue";
import store from "@/store";
import { mapGetters } from "vuex";
export default {
  props: {
    chatType: {
      type: String,
      required: true,
    },
  },
  components: { BFormTextarea, BFormInput, BToast },
  data() {
    return {
      success: false,
      description: "",
      about: "",
      chatContent: {
        suggestion: {
          title: this.$t("oneribaslik"),
          subTitle: this.$t("onerialtbaslik"),
          textAreaTitle: this.$t("oneritextarea"),
          buttonText: this.$t("oneributtontext"),
        },
        complaint: {
          title: this.$t("sikayetbaslik"),
          subTitle: this.$t("sikayetaltbaslik"),
          textAreaTitle: this.$t("sikayettextarea"),
          buttonText: this.$t("Gönder"),
        },
        cusService: {
          title: this.$t("musterihizmetleribaslik"),
          subTitle: this.$t("musterihizmetlerialtbaslik"),
          textAreaTitle: this.$t("musterihizmetleritextarea"),
          buttonText: this.$t("musterihizmetlericanlidestekbuttontext"),
        },
      },
    };
  },
  methods: {
    closeSuccess() {
      this.success = false;
      this.description = "";
      this.about = "";
    },
    checkValid() {
      if (this.description.length > 0 && this.about.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    async send() {
      if (this.checkValid()) {
        var json = {
          ACIKLAMA: this.description,
          KONU: this.about,
          ID: this.getUser.email,
          NAME: this.getUser.musteriAd,
          TIP: this.chatType == "suggestion" ? "O" : "S",
        };

        var jsonconvert = JSON.stringify(json);
        var request = {
          FunctionName: "d7kRun",
          MacroName: "D7KSUP007",
          JsonParams: jsonconvert,
          MacroKey: "B2BOneriSikayetKayit",
        };
        try {
          await this.$http
            .post("Com/RunMacroWithAuth", request)
            .then((response) => {
              if (response.status == 200) {
                if (response.data.succeeded == false) {
                  this.$bvToast.toast(response.data.message, {
                    title: "Makro Hatası",
                    variant: "danger",
                    autoHideDelay: 2000,
                    appendToast: false,
                  });
                  return;
                } else {
                  this.$bvToast.toast("İşlem Başarılı", {
                    title: "Başarılı",
                    variant: "success",
                    autoHideDelay: 4000,
                    appendToast: false,
                  });
                  this.success = true;
                }
              } else {
                // Api dönüş hatasına bakıcam
                this.$bvToast.toast(response.data.message, {
                  title: "Api Hatası",
                  variant: "danger",
                  autoHideDelay: 2000,
                  appendToast: false,
                });
              }
            });
        } catch {
          this.$bvToast.toast(
            "Web tarafın bir sorun oluştu sistem yöneticiniz ile görüşünüz.!",
            {
              title: "Web Hatası",
              variant: "danger",
              autoHideDelay: 2000,
              appendToast: false,
            }
          );
        }
      } else {
        this.$bvToast.toast("Lütfen Tüm Alanları Doldurunuz", {
          title: `Hata`,
          toaster: "b-toaster-bottom-right",
          variant: "danger",
          solid: true,
        });
      }
    },
    closeChat() {
      this.$emit("closeChat");
    },
  },
  computed: {
    ...mapGetters("signalRStore", ["getUser"]),
  },
};
</script>
