<template>
  <div>
    <div v-if="!isChatOpen">
      <SubBuble
        v-if="!isWidgetOpen"
        bubble-type="default"
        :is-widget-open="isWidgetOpen"
        @changeIsOpen="changeIsOpen"
      />
      <SubBuble
        v-if="isWidgetOpen"
        bubble-type="close"
        @changeIsOpen="changeIsOpen"
      />

      <transition name="slide-left">
        <SubBuble
          v-if="isWidgetOpen"
          bubble-type="suggestion"
          @click="openChat('suggestion')"
          @openChat="openChat"
        />
      </transition>

      <transition name="slide-bottom-right">
        <SubBuble
          v-if="isWidgetOpen"
          bubble-type="complaint"
          @click="openChat('complaint')"
          @openChat="openChat"
        />
      </transition>

      <!-- <transition name="slide-up">
        <SubBuble
          v-if="isWidgetOpen"
          bubble-type="cusService"
          @click="openChat('cusService')"
          @openChat="openChat"
        />
      </transition> -->
    </div>
    <div v-else>
      <chat-widget :chat-type="chatType" @closeChat="closeChat" />
    </div>
  </div>
</template>
<style scoped>
/* WebKit (Chrome, Safari, Edge) */

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.3s;
}
.slide-up-enter,
.slide-up-leave-to {
  transform: translateY(100%);
  opacity: 0;
}

.slide-bottom-right-enter-active,
.slide-bottom-right-leave-active {
  transition: all 0.3s;
}
.slide-bottom-right-enter,
.slide-bottom-right-leave-to {
  transform: translate(100%, 100%);
  opacity: 0;
}
.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 0.3s;
}
.slide-left-enter,
.slide-left-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
</style>
<script>
import store from "@/store";
import ChatWidget from "./ChatWidget.vue";
import SubBuble from "./SubWidget/SubBuble.vue";

export default {
  components: {
    SubBuble,
    ChatWidget,
  },
  data() {
    return {
      isWidgetOpen: false,
      isChatOpen: false,
      chatType: "suggestion",
    };
  },
  async created() {
    await store.dispatch("signalRStore/connectUseTrackerHub");
    await store.dispatch("signalRStore/connectCustomerSupportHub");
  },
  methods: {
    openCustomerSupport() {
      this.customerSupportChat = !this.customerSupportChat;
    },
    changeIsOpen() {
      this.isWidgetOpen = !this.isWidgetOpen;
    },
    openChat(chatType) {
      this.chatType = chatType;
      this.isChatOpen = true;
    },
    closeChat() {
      this.isChatOpen = false;
      this.isWidgetOpen = false;
    },
  },
};
</script>
