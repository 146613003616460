<template>
  <div v-if="checkRating" class="chatRating">
    <div
      v-for="question in getRatingQuestions"
      :key="question.id"
      class="mb-4 pb-4"
      style="border-bottom: 1px solid #0000001a"
    >
      <div class="p-1 text-center">
        <p style="font-weight: bold">
          {{ question.question }}
        </p>
        <p>
          {{ question.description }}
        </p>
      </div>
      <transition name="grow">
        <div
          class="d-flex w-100"
          style="justify-content: center"
          v-if="getSelectedStar(question.id)"
        >
          <img :src="selectStar" alt="" style="width: 50px" />
        </div>
      </transition>
      <div
        class="d-flex w-100 text-center align-center mb-2"
        style="justify-content: center"
      >
        <span style="font-size: 25px; font-weight: bold; color: #f48716">{{
          getSelectedStar(question.id) || getHoverStar(question.id)
        }}</span>
      </div>
      <div
        class="starDiv"
        @mouseleave="leaveStar(question.id)"
        v-if="!getSelectedStar(question.id)"
      >
        <img
          :src="getHoverStar(question.id) < i ? unSelectStar : selectStar"
          alt=""
          v-for="i in 5"
          :key="i"
          class="starImg"
          @mouseover="hoverStar(i, question.id)"
          @click="setStar(i, question.id)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import selectStar from "@/assets/images/icons/select-star.svg";
import unSelectStar from "@/assets/images/icons/unselect-star.svg";
export default {
  data() {
    return {
      hoverStarId: [],
      ratingQuestions: [],
      selectStar: selectStar,
      unSelectStar: unSelectStar,
    };
  },
  computed: {
    ...mapGetters("signalRStore", ["getTicket", "getUser"]),
    ...mapGetters("ratingStore", ["getRatingQuestions"]),
    checkRating() {
      if (this.getTicket && this.getTicket.data) {
        return this.getTicket.data.status == 4 &&
          this.getTicket.data.isRated == false
          ? true
          : false;
      } else {
        return false;
      }
    },
  },
  methods: {
    hoverStar(starId, question) {
      const findValue = this.hoverStarId.find(
        (questionVal) => questionVal.questionId == question
      );
      if (!findValue) {
        const questionValue = {
          questionId: question,
          value: starId,
        };
        this.hoverStarId.push(questionValue);
      } else {
        findValue.value = starId;
      }
    },
    getSelectedStar(id) {
      const findVal = this.ratingQuestions.find(
        (rating) => rating.questionId == id
      );
      if (findVal) {
        return findVal.value;
      } else {
        return null;
      }
    },
    leaveStar(question) {
      const findValue = this.hoverStarId.find(
        (questionVal) => questionVal.questionId == question
      );
      return findValue && findValue.value ? (findValue.value = 0) : 0;
    },
    getHoverStar(id) {
      const findValue = this.hoverStarId.find(
        (questionVal) => questionVal.questionId == id
      );
      return findValue && findValue.value ? findValue.value : 0;
    },
    setStar(value, questionId) {
      const questionVal = {
        questionId: questionId,
        value: value,
      };
      this.ratingQuestions.push(questionVal);
      const addQuestion = {
        questionId: questionId,
        score: value,
        userId: Number(this.getUser.id),
      };

      this.$store.dispatch("ratingStore/addQuestion", addQuestion);
    },
  },
  watch: {
    checkRating(value) {
      this.hoverStarId = [];
      this.ratingQuestions = [];
      if (value) {
        this.$store.dispatch("ratingStore/fetchAllRatingTemplate");
        const payload = {
          userId: Number(this.getUser.id),
          ticketId: this.getTicket.data.id,
        };
        this.$store.dispatch("ratingStore/fetchRating", payload);
      }
    },
    ratingQuestions(value) {
      this.$store.dispatch("ratingStore/fetchAllRatingTemplate");
      const payload = {
        userId: Number(this.getUser.id),
        ticketId: this.getTicket.data.id,
      };
      this.$store.dispatch("ratingStore/fetchRating", payload);
      if (value.length == this.getRatingQuestions.length) {
        this.$store.dispatch("signalRStore/updateIsRating", this.getTicket);
      }
    },
  },
  created() {
    this.$store.dispatch("ratingStore/fetchAllRatingTemplate");
    try {
      const payload = {
        userId: Number(this.getUser.id),
        ticketId: this.getTicket.data.id,
      };
      this.$store.dispatch("ratingStore/fetchRating", payload);
    } catch (error) {}
  },
};
</script>
<style scoped>
.grow-enter-active,
.grow-leave-active {
  transition: all 0.5s;
}

.grow-enter, .grow-leave-to /* .grow-leave-active in <2.1.8 */ {
  transform: scale(0);
  opacity: 0;
}
.chatRating::-webkit-scrollbar {
  width: 5px;
}

.chatRating::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Scrollbar izleme alanının arka plan rengi */
}

.chatRating::-webkit-scrollbar-thumb {
  background-color: #229000; /* Scrollbar başparmak kısmının rengi */
  border-radius: 4px; /* Scrollbar başparmak kısmının köşe yuvarlaklığı */
  z-index: 150;
}

.chatRating::-webkit-scrollbar-thumb:hover {
  background-color: #33ff00; /* Scrollbar başparmak kısmının hover rengi */
}
.starDiv {
  gap: 6px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  cursor: pointer;
}
.chatRating {
  display: flex;
  overflow-x: hidden;
  height: 100%;
  flex-direction: column;
}
.starImg {
  width: 30px;
}
</style>