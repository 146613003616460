<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <!-- <dark-Toggler class="d-none d-lg-block" /> -->
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <Locale />
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">{{ username }}</p>
            <span class="user-status">{{ title }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="img"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <!-- <b-dropdown-item :to="{ name: 'pages-profile'}" link-class="d-flex align-items-center">
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>{{ $t('Profile')}}</span>
        </b-dropdown-item>-->

        <!-- <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="16" icon="MailIcon" class="mr-50" />
          <span>Inbox</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="16" icon="CheckSquareIcon" class="mr-50" />
          <span>Task</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="16" icon="MessageSquareIcon" class="mr-50" />
          <span>Chat</span>
        </b-dropdown-item>-->

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>{{ $t("Log out") }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import Locale from "./Locale.vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import store from "@/store";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    DarkToggler,
    Locale,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      username: "",
      title: "",
      img: "",
    };
  },
  mounted() {
    var data = JSON.parse(localStorage.getItem("B2BUserData"));
    this.username = data.fullName;
    this.img = data.profileImgUrl;
    this.title = data.title;
  },
  methods: {
    logout() {
      localStorage.removeItem("B2BUserToken");
      localStorage.removeItem("B2BsecilenFirmaKeyi");
      localStorage.removeItem("B2BUserMenu");
      localStorage.removeItem("B2BUserData");
      localStorage.removeItem("SignalRToken");
      localStorage.removeItem("customerSupportHubConnection");
      localStorage.removeItem("userTrackerConnection");
      store.dispatch("signalRStore/closeConnection");
      // Redirect to login page
      this.$router.push({ name: "login" });
    },
  },
};
</script>
