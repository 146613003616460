<template>
  <div
    v-if="startNewChat && !connectionStart && !checkRating"
    :style="newTicketDiv.style"
    :class="newTicketDiv.class"
  >
    <div :class="newTicketDiv.title.class" :style="newTicketDiv.title.class">
      <span>Her Zaman Yardıma Hazırız</span><br />
      <span class="font-weight-bold">{{ getUser.fullName }}</span>
    </div>
    <div>
      <b-form-select v-model="selectPriority" :options="options" class="mb-1">
      </b-form-select>
      <input
        :type="ticketAboutDiv.input.type"
        :placeholder="ticketAboutDiv.input.placeholder"
        :class="ticketAboutDiv.input.class"
        :style="ticketAboutDiv.input.style"
        v-model="ticketAbout"
        maxlength="50"
      />
      <textarea
        :rows="ticketAboutDiv.textarea.rows"
        :class="ticketAboutDiv.textarea.class"
        :style="ticketAboutDiv.textarea.style"
        v-model="ticketText"
        :placeholder="ticketAboutDiv.textarea.placeholder"
      ></textarea>

      <b-overlay
        :show="busy"
        rounded
        opacity="0.6"
        spinner-small
        spinner-variant="primary"
        class="d-inline-block"
      >
        <b-button
          variant="success"
          class="btn btn-block"
          @click="startChat"
          :disabled="busy"
        >
          <span style="color: white"> Canlı Desteğe Bağlan </span>
        </b-button>
      </b-overlay>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import { mapGetters } from "vuex";
import { BButton, BFormSelect, BOverlay, BToast } from "bootstrap-vue";
export default {
  data() {
    return {
      newTicketDiv: {
        class: "h-100 p-2 text-center d-flex flex-column",
        style: "background-color: white",
        title: {
          class: "d-flex flex-column h-50",
          style: "justify-content: space-evenly",
        },
      },
      ticketAboutDiv: {
        input: {
          type: "text",
          placeholder: "Konu",
          class: "w-100 mb-1",
          style:
            " border-radius: 5px;padding: 5px;border: 1px solid black;height: 37px;",
        },
        textarea: {
          rows: "5",
          class: "w-100 p-1",
          style: "resize: none; outline: none; border-radius: 5px",
          placeholder: "Sorunuzu veya mesajınızı buraya yazınız",
        },
      },
    };
  },
  computed: {
    ...mapGetters("customerSupportStore", {
      connectionStart: "getConnectionStart",
      options: "getOptions",
    }),
    ...mapGetters("signalRStore", ["getUser", "getTicket"]),
    selectPriority: {
      get() {
        return store.getters["customerSupportStore/getSelectPriority"];
      },
      set(newValue) {
        store.commit("customerSupportStore/setSelectPriority", newValue);
      },
    },
    ticketAbout: {
      get() {
        return store.getters["customerSupportStore/getTicketAbout"];
      },
      set(newValue) {
        store.commit("customerSupportStore/setTicketAbout", newValue);
      },
    },
    ticketText: {
      get() {
        return store.getters["customerSupportStore/getTicketText"];
      },
      set(newValue) {
        store.commit("customerSupportStore/setTicketText", newValue);
      },
    },
    busy: {
      get() {
        return store.getters["customerSupportStore/getBusy"];
      },
      set(newValue) {
        store.commit("customerSupportStore/setBusy", newValue);
      },
    },
    startNewChat: {
      get() {
        return store.getters["customerSupportStore/getStartNewChat"];
      },
      set(newValue) {
        store.commit("customerSupportStore/setStartNewChat", newValue);
      },
    },
    checkRating() {
      if (this.getTicket && this.getTicket.data) {
        return this.getTicket.data.status == 4 ? true : false;
      } else {
        return false;
      }
    },
  },
  components: {
    BFormSelect,
    BOverlay,
    BButton,
    BToast,
  },
  methods: {
    async startChat() {
      if (
        this.ticketText == "" ||
        this.ticketAbout == "" ||
        this.selectPriority == null
      ) {
        this.$bvToast.toast("Lütfen Tüm Alanları Doldurunuz", {
          title: `Hata`,
          toaster: "b-toaster-bottom-left",
          variant: "danger",
          solid: true,
        });
      } else {
        store.commit("customerSupportStore/setBusy", true);
        const ticket = {
          textTicket: this.ticketText,
          textAbout: this.ticketAbout,
          priority: this.selectPriority,
        };
        await store
          .dispatch("signalRStore/createTicket", ticket)
          .then((data) => {
            this.busy = false;
          })
          .catch((err) => {});
        store.commit("customerSupportStore/setConnectionStart", true);
      }
    },
  },
};
</script>