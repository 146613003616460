<template>
  <div>
    <p class="clearfix mb-0">
      <span class="float-md-left d-block d-md-inline-block mt-25">
        COPYRIGHT © {{ new Date().getFullYear() }}
        <b-link class="ml-25" href="https://d7ksuper.com/" target="_blank"
          >D7KSuper</b-link
        >
        <span class="d-none d-sm-inline-block">, All rights Reserved</span>
      </span>
    </p>
    <Widget />
    <CustomerSupport />
  </div>
</template>

<script>
import Widget from "@/layouts/components/Widgets/BubleWidget/BubleWidget.vue";
import CustomerSupport from "@/layouts/components/Widgets/CustomerSupport/CustomerSupport.vue";
import { BLink } from "bootstrap-vue";

export default {
  components: {
    Widget,
    BLink,
    CustomerSupport,
  },
};
</script>
