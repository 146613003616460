<template>
  <b-nav-item-dropdown id="dropdown-grouped" variant="link" class="dropdown-language" right>
    <template #button-content>
      <b-img
        :src="currentLocale!= null ? currentLocale.img : '' "
        height="14px"
        width="22px"
        :alt="currentLocale.locale"
      />
      <span class="ml-50 text-body">{{ currentLocale!= null? currentLocale.name : "" }}</span>
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="localeSet(localeObj)"
    >
      <b-img :src="localeObj.img" height="14px" width="22px" :alt="localeObj.locale" />
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from "bootstrap-vue";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  // computed: {
  //   currentLocale() {
  //     const locale = localStorage.getItem("locale");
  //     if (locale) {
  //       return this.locales.find(l => l.locale === locale);
  //     }
  //     return this.locales.find(l => l.locale === this.$i18n.locale);
  //   }
  // },
  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: "en",
        img: require("@/assets/images/flags/en.png"),
        name: "English"
      },
      {
        locale: "de",
        img: require("@/assets/images/flags/de.png"),
        name: "German"
      },
      {
        locale: "tr",
        img: require("@/assets/images/flags/tr.png"),
        name: "Turkish"
      }
      // {
      //   locale: "fr",
      //   img: require("@/assets/images/flags/fr.png"),
      //   name: "French"
      // },

      // {
      //   locale: "pt",
      //   img: require("@/assets/images/flags/pt.png"),
      //   name: "Portuguese"
      // }
    ];

    const currentLocale = null;
    return {
      locales,
      currentLocale
    };
  },
  created() {
    let locale = localStorage.getItem("locale");
    if (locale) {
      this.$i18n.locale = locale;
      locale = this.locales.find(l => l.locale === locale);
    } else {
      locale = this.locales.find(l => l.locale === this.$i18n.locale);
    }
    this.currentLocale = locale;
  },
  methods: {
    localeSet(set) {
      //console.log(set);
      this.$i18n.locale = set.locale;
      localStorage.setItem("locale", set.locale);
      this.currentLocale = set;
    }
  }
};
</script>

<style>
</style>
