var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.connectionStart && !_vm.checkRating)?_c('div',{class:_vm.mainDiv.class,style:(_vm.mainDiv.style)},[_c('div',{ref:"chatScreen",class:_vm.chatScreen.class,staticStyle:{"background-color":"#f5f5f6"}},[_c('div',{staticClass:"ticketValue"},[_c('span',{staticStyle:{"font-size":"calc(10px + 0.3vw)"}},[_c('b',[_vm._v(_vm._s(_vm.getTicket.data ? _vm.getTicket.data.readableID ? _vm.getTicket.data.readableID : "" : ""))])]),_c('span',{staticStyle:{"font-size":"calc(10px + 0.3vw)"}},[_c('b',[_vm._v(_vm._s(_vm.getTicket.data ? _vm.getTicket.data.subject ? _vm.getTicket.data.subject : "" : ""))])]),_c('span',[_vm._v(" "+_vm._s(_vm.getTicket.data ? _vm.getTicket.data.description ? _vm.getTicket.data.description : "" : "")+" ")])]),_vm._l((_vm.getMessageList),function(message,index){return _c('div',{key:index,staticClass:"d-flex",style:(message.SenderId == _vm.getUserId || message.senderId == _vm.getUserId
          ? 'justify-content: flex-end; padding-right: 15px'
          : 'justify-content: flex-start; padding-left: 15px')},[_c('div',{class:message.SenderId == _vm.getUserId || message.senderId == _vm.getUserId
            ? 'bubble alt'
            : 'bubble',style:(message.SenderId == _vm.getUserId || message.senderId == _vm.getUserId
            ? '  background: #ebffd9;'
            : '  background: white;')},[_c('div',{staticClass:"txt"},[_c('p',{class:message.SenderId == _vm.getUserId || message.senderId == _vm.getUserId
                ? 'name alt'
                : 'name'},[_vm._v(" "+_vm._s(_vm.userName(message.SenderId || message.senderId))+" ")]),_c('p',{staticClass:"message"},[_vm._v(" "+_vm._s(message.message || message.Message)+" ")]),_c('span',{staticClass:"timestamp",staticStyle:{"font-size":"calc(7px + 0.3vw)"}},[_vm._v(_vm._s(_vm.getTime(message.sentDate)))])]),_c('div',{class:message.SenderId == _vm.getUserId || message.senderId == _vm.getUserId
              ? 'bubble-arrow alt'
              : 'bubble-arrow'})])])})],2),_c('div',{staticStyle:{"display":"flex","position":"relative"}},[(_vm.getIsTyping)?_c('div',{staticClass:"typing"},[_c('span',[_vm._v(" yazıyor... ")])]):_vm._e(),(!_vm.checkRated)?_c('div',{staticStyle:{"display":"flex","width":"100%"}},[_c('b-form-textarea',{staticClass:"textArea",staticStyle:{"padding-right":"60px !important"},attrs:{"id":"textarea-rows","placeholder":"Buraya yazınız","rows":"4","no-resize":"","autofocus":""},on:{"keyup":_vm.handleKeyDown},model:{value:(_vm.messageText),callback:function ($$v) {_vm.messageText=$$v},expression:"messageText"}}),_c('div',{staticStyle:{"display":"flex","position":"absolute","top":"5px","right":"5px"}},[_c('b-button',{staticClass:"rounded-circle",staticStyle:{"padding":"10px !important"},attrs:{"size":"sm","variant":"success","disabled":_vm.messageText < 1},on:{"click":_vm.sendMessage}},[_c('arrow-up-circle-icon',{staticClass:"custom-class",attrs:{"size":"1.5x"}})],1)],1)],1):_c('div',{staticStyle:{"color":"#8080808a","align-self":"start","display":"inline-flex","width":"100%","height":"100px","align-items":"center"}},[_vm._v(" Bu Destek Talebi Müşteri Temsilciniz Tarafından Sonlandırılmıştır. ")]),_vm._m(0)])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div')])}]

export { render, staticRenderFns }