export default [
  {
    title: 'urunler',
    route: 'urunler',
    icon: 'ListIcon',
  },
  {
    title: 'siparislerim',
    route: 'siparislerim',
    icon: 'CreditCardIcon',
  }, {
    title: 'cariHesap',
    route: 'cariHesap',
    icon: 'BarChart2Icon',
  }, {
    title: 'tekliftopla',
    route: 'tekliftopla',
    icon: 'ListIcon',
  },

]
