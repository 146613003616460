<template>
  <div>
    <div
      class="customerSupportWidget transition"
      :style="customerSupportChat ? 'height:500px!important' : 'height: 40px;'"
    >
      <ChatTitle />
      <ChatRating />
      <ChatWelcome />
      <ChatNewTicket />
      <ChatScreen />
    </div>
  </div>
</template>
<script>
import ChatTitle from "./ChildComponent/ChatTitleComponent.vue";
import { mapGetters } from "vuex";
import store from "@/store";
import ChatWelcome from "./ChildComponent/ChatWelcome.vue";
import ChatNewTicket from "./ChildComponent/ChatNewTicket.vue";
import ChatScreen from "./ChildComponent/ChatScreen.vue";
import ChatRating from "./ChildComponent/ChatRating.vue";
export default {
  data() {
    return {};
  },
  methods: {
    async getAllTickets() {
      store.dispatch("customerSupportStore/getAllTickets");
    },
  },
  components: {
    ChatTitle,
    ChatWelcome,
    ChatNewTicket,
    ChatScreen,
    ChatRating,
  },
  computed: {
    ...mapGetters("signalRStore", {
      updateTicket: "getUpdateTicket",
    }),
    customerSupportChat: {
      get() {
        return store.getters["customerSupportStore/getCustomerSupportChat"];
      },
      set(newValue) {
        store.commit("customerSupportStore/setCustomerSupportChat", newValue);
      },
    },
  },
  async created() {
    await this.getAllTickets();
  },
  watch: {
    async updateTicket(newValue) {
      if (newValue) {
        this.$store.dispatch("signalRStore/checkSelectedTicket", newValue);
        await this.$store.dispatch(
          "customerSupportStore/updateTicketList",
          newValue
        );
        await this.$store.dispatch("customerSupportStore/getUserDatas");
        this.$store.dispatch("customerSupportStore/getData");
      }
    },
  },
};
</script>
<style scoped>
.customerSupportWidget {
  border-radius: 10px 30px 0px 0px;
  overflow: visible;
  display: flex;
  width: 333px;
  background-color: white;
  flex-direction: column;
  border-radius: 10px 10px 0px 0px;
  box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 24%) !important;
  position: fixed;
  z-index: 999;
  bottom: 0px;
  left: 1.6vw;
}
.transition {
  transition: all ease 0.5s;
}
</style>