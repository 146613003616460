<template>
  <div
    :style="mainDiv.style"
    :class="mainDiv.class"
    v-if="connectionStart && !checkRating"
  >
    <div
      :class="chatScreen.class"
      ref="chatScreen"
      style="background-color: #f5f5f6"
    >
      <div class="ticketValue">
        <span style="font-size: calc(10px + 0.3vw)">
          <b>{{
            getTicket.data
              ? getTicket.data.readableID
                ? getTicket.data.readableID
                : ""
              : ""
          }}</b>
        </span>
        <span style="font-size: calc(10px + 0.3vw)">
          <b>{{
            getTicket.data
              ? getTicket.data.subject
                ? getTicket.data.subject
                : ""
              : ""
          }}</b>
        </span>

        <span>
          {{
            getTicket.data
              ? getTicket.data.description
                ? getTicket.data.description
                : ""
              : ""
          }}
        </span>
      </div>
      <div
        class="d-flex"
        :style="
          message.SenderId == getUserId || message.senderId == getUserId
            ? 'justify-content: flex-end; padding-right: 15px'
            : 'justify-content: flex-start; padding-left: 15px'
        "
        v-for="(message, index) in getMessageList"
        :key="index"
      >
        <div
          :class="
            message.SenderId == getUserId || message.senderId == getUserId
              ? 'bubble alt'
              : 'bubble'
          "
          :style="
            message.SenderId == getUserId || message.senderId == getUserId
              ? '  background: #ebffd9;'
              : '  background: white;'
          "
        >
          <div class="txt">
            <p
              :class="
                message.SenderId == getUserId || message.senderId == getUserId
                  ? 'name alt'
                  : 'name'
              "
            >
              <!-- {{ userName(message.SenderId || message.senderId) }} -->
              {{ userName(message.SenderId || message.senderId) }}
            </p>
            <p class="message">
              {{ message.message || message.Message }}
            </p>
            <span class="timestamp" style="font-size: calc(7px + 0.3vw)">{{
              getTime(message.sentDate)
            }}</span>
          </div>
          <div
            :class="
              message.SenderId == getUserId || message.senderId == getUserId
                ? 'bubble-arrow alt'
                : 'bubble-arrow'
            "
          ></div>
        </div>
      </div>
    </div>
    <div style="display: flex; position: relative">
      <div class="typing" v-if="getIsTyping">
        <span> yazıyor... </span>
      </div>
      <div style="display: flex; width: 100%" v-if="!checkRated">
        <b-form-textarea
          id="textarea-rows"
          placeholder="Buraya yazınız"
          rows="4"
          no-resize
          autofocus
          class="textArea"
          v-model="messageText"
          @keyup="handleKeyDown"
          style="padding-right: 60px !important"
        ></b-form-textarea>
        <div style="display: flex; position: absolute; top: 5px; right: 5px">
          <b-button
            class="rounded-circle"
            size="sm"
            variant="success"
            @click="sendMessage"
            style="padding: 10px !important"
            :disabled="messageText < 1"
            ><arrow-up-circle-icon
              size="1.5x"
              class="custom-class"
            ></arrow-up-circle-icon
          ></b-button>
        </div>
      </div>
      <div
        style="
          color: #8080808a;
          align-self: start;
          display: inline-flex;
          width: 100%;
          height: 100px;
          align-items: center;
        "
        v-else
      >
        Bu Destek Talebi Müşteri Temsilciniz Tarafından Sonlandırılmıştır.
      </div>
      <div>
        <div></div>
      </div>
    </div>
  </div>
</template>
<script>
import { BFormTextarea, BButton } from "bootstrap-vue";
import store from "@/store";
import { ArrowUpCircleIcon } from "vue-feather-icons";
import { mapGetters } from "vuex";

export default {
  components: {
    BFormTextarea,
    BButton,
    ArrowUpCircleIcon,
  },
  data() {
    return {
      typingTimeout: null,
      typing: false,
      mainDiv: {
        class: "text-center d-flex flex-column",
        style:
          "background-color: white; height: 90%; justify-content: space-between",
      },
      chatScreen: {
        class: "chatScreen speech-wrapper d-flex flex-column",
        style: {
          backgroundImage:
            "url('" +
            require("@/assets/images/widgets/chatBackGround.png") +
            "')",
        },
      },
    };
  },
  computed: {
    checkRated() {
      if (this.getTicket.data && this.getTicket.data.isRated) {
        return this.getTicket.data.isRated;
      } else {
        return false;
      }
    },
    ...mapGetters("signalRStore", [
      "getTicket",
      "getMessageList",
      "getUserId",
      "getUser",
      "getIsTyping",
    ]),
    ...mapGetters("customerSupportStore", {
      connectedUser: "getCustomerConnectedUser",
    }),
    connectionStart: {
      get() {
        return store.getters["customerSupportStore/getConnectionStart"];
      },
      set() {
        store.commit("customerSupportStore/setConnectionStart");
      },
    },
    messageText: {
      get() {
        return store.getters["customerSupportStore/getMessageText"];
      },
      set(newValue) {
        store.commit("customerSupportStore/setMessageText", newValue);
      },
    },
    userName() {
      return (senderId) => {
        if (senderId == this.getUserId) {
          return this.getUser.musteriAd;
        } else {
          const user = this.connectedUser.find((user) => {
            return user.id === senderId;
          });
          if (user) {
            return `${user.name} ${user.lastName}`;
          } else {
            store.dispatch("customerSupportStore/addIdList", senderId);
            store.dispatch("customerSupportStore/getData");
            const user = this.connectedUser.find((user) => {
              return user.id === senderId;
            });
            return `${user.name} ${user.lastName}`;
          }
        }
      };
    },
    checkRating() {
      if (this.getTicket && this.getTicket.data) {
        if (this.getTicket.data.isRated) {
          return this.getTicket.data.status == 4 &&
            this.getTicket.data.isRated == false
            ? true
            : false;
        } else {
          return this.getTicket.data.status == 4 ? true : false;
        }
      } else {
        return false;
      }
    },
  },
  methods: {
    getTime(time) {
      if (time) {
        const utcDate = new Date(time);
        const hours = utcDate.getHours();
        const minutes = utcDate.getMinutes();
        const formattedMinute = minutes < 10 ? `0${minutes}` : `${minutes}`;
        return `${hours}:${formattedMinute}`;
      }
    },
    priorityValue(priority) {
      switch (priority) {
        case 1:
          return "Düşük";
        case 2:
          return "Normal";
        case 3:
          return "Öncelikli";
        case 4:
          return "Acil";
        default:
          return "Bilinmeyen Öncelik";
      }
    },
    handleKeyDown(event) {
      if (event.shiftKey && event.key === "Enter") {
      } else if (event.key === "Enter") {
        this.sendMessage();
      }
    },
    async sendMessage() {
      if (this.messageText.length > 2) {
        const ObjectID = require("bson-objectid");
        const messageValue = {
          messageText: this.messageText,
          id: ObjectID().toHexString(),
        };
        await store.dispatch("signalRStore/sendMessage", messageValue);
      }

      store.commit("customerSupportStore/setMessageText", "");
    },
  },
  watch: {
    getMessageList(newMessages) {
      if (newMessages && newMessages.length > 0 && this.connectionStart) {
        this.$nextTick(() => {
          const chatScreen = this.$refs.chatScreen;
          if (chatScreen && chatScreen.scrollTop && chatScreen.scrollHeight) {
            chatScreen.scrollTop = chatScreen.scrollHeight;
          }
        });
      }
    },
    messageText(newValue, oldValue) {
      if (!this.typing) {
        this.typing = true;
      }
      clearTimeout(this.typingTimeout);
      this.typingTimeout = setTimeout(() => {
        this.typing = false;
      }, 1000);
    },
    typing(newVal, oldVal) {
      const isTyping = {
        ChatId: this.getTicket.data ? this.getTicket.data.id : null,
        UserId: this.getUserId,
        IsTyping: newVal,
      };
      store.dispatch("signalRStore/sendTyping", isTyping);
    },
  },
};
</script>
<style scoped>
.typing {
  display: flex;
  background: #ffffffb5;
  padding: 3px 5px;
  color: green;
  position: absolute;
  text-align: left;
  top: -25px;
  width: calc(100% - 5px);
}
.chatMessage {
}
.chatScreen {
  position: relative;
  overflow-y: scroll;
  max-height: 80%;
  height: 80%;
  overflow-x: hidden;
  background-color: #e2dad2;
  background-size: cover;
  padding-bottom: 25px;
}
.chatScreen::-webkit-scrollbar,
.textArea::-webkit-scrollbar {
  width: 5px;
}

.chatScreen::-webkit-scrollbar-track,
.textArea::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Scrollbar izleme alanının arka plan rengi */
}

.chatScreen::-webkit-scrollbar-thumb,
.textArea::-webkit-scrollbar-thumb {
  background-color: #229000; /* Scrollbar başparmak kısmının rengi */
  border-radius: 4px; /* Scrollbar başparmak kısmının köşe yuvarlaklığı */
  z-index: 150;
}

.chatScreen::-webkit-scrollbar-thumb:hover,
.textArea::-webkit-scrollbar-thumb:hover {
  background-color: #33ff00; /* Scrollbar başparmak kısmının hover rengi */
}
.speech-wrapper {
  display: flex;
}
.speech-wrapper .bubble {
  white-space: pre-line;
  width: 240px;
  height: auto;
  display: block;
  text-align: left;
  border-radius: 4px;
  box-shadow: -3px 3px 5px #0000002e;
  position: relative;
  margin: 0 0 25px;
}
.speech-wrapper .bubble.alt {
  text-align: left;
}
.speech-wrapper .bubble .txt {
  word-break: break-word;
  padding: 8px 55px 8px 14px;
}
.speech-wrapper .bubble .txt .name {
  font-weight: 600;
  font-size: 10px;
  margin: 0 0 4px;
  color: #3498db;
}
.speech-wrapper .bubble .txt .name span {
  font-weight: normal;
  color: #b3b3b3;
}
.speech-wrapper .bubble .txt .name.alt {
  color: #2ecc71;
}
.speech-wrapper .bubble .txt .message {
  font-size: 12px;
  margin: 0;
  color: #2b2b2b;
}
.speech-wrapper .bubble .txt .timestamp {
  font-size: 11px;
  position: absolute;
  bottom: 8px;
  right: 10px;
  text-transform: uppercase;
  color: #999;
}
.speech-wrapper .bubble .bubble-arrow {
  position: absolute;
  width: 0;
  bottom: 42px;
  left: -16px;
  height: 0;
}
.speech-wrapper .bubble .bubble-arrow.alt {
  right: -2px;
  bottom: 40px;
  left: auto;
}
.speech-wrapper .bubble .bubble-arrow:after {
  content: "";
  position: absolute;
  border: 0 solid transparent;
  border-top: 9px solid white;
  border-radius: 0 20px 0;
  width: 15px;
  height: 30px;
  transform: rotate(145deg);
}
.speech-wrapper .bubble .bubble-arrow.alt:after {
  transform: rotate(45deg) scaleY(-1);
  border-top: 9px solid #ebffd9;
}
.ticketValue {
  width: 100%;
  border: 1px solid #00000026;
  border-radius: 5px;
  margin: 15px auto;
  background-color: #ffffffb3;
  padding: 8px 8px;
  row-gap: 5px;
  display: flex;
  font-size: calc(7px + 0.3vw);
  flex-direction: column;
  text-align: left;
  text-wrap: wrap;
  word-break: break-all;
}
</style>