<template>
  <div
    :class="bubble[bubbleType].class"
    @click="changeIsOpen(bubbleType)"
  >
    <!-- parent componentden gelen veriye göre nesne içinden istediğimiz veriyi alıyoruz -->
    <img
      :src="bubble[bubbleType].icon"
      alt="Send Icon"
    >
    <label for="">
      {{ bubble[bubbleType].text?bubble[bubbleType].text:'' }}
    </label>
  </div>
</template>
<style scoped>
.widgetDef label{
  margin: 0px;
  text-align: center;
  font-weight: bold;
  color:black;
}
.widgetDef
{ display: flex;
  user-select: none;
  flex-direction: column;
  position: fixed;
  width: 74px;
  height: 74px;
  background-color: #F9EC01;
  justify-content: center;
  align-items: center;
  border: 0px;
  z-index: 999;
  position: fixed;
  bottom: 50px;
  right: 60px;
  box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 24%) !important;;
  cursor: pointer;}

.defaultWidget, .closeWidget {
  bottom: 50px;
  right: 60px;
  border-radius: 37px 37px 0px 37px !important ;
}
.suggestionWidget{
  bottom:50px;
  right: 160px;
  border-radius: 37px !important ;
}
.complaintWidget{
  bottom: 130px;
  right: 130px;
  border-radius: 37px !important ;

}
.cusService{
  bottom: 150px;
  right: 50px;
  border-radius: 37px !important ;

}
</style>
<script>
export default {
  // Üst komponentden gelen bubbleType'a göre içerik şekilleniyor
  props: {
    bubbleType: {
      type: String,
      required: true,
    },
    isWidgetOpen: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      bubble: {
        // buble isimli bir nesne oluşturup componenti bu nesne üzerinden şekillendiriyoruz
        default: {
          class: 'defaultWidget widgetDef',
          icon: require('@/assets/images/widgets/send.svg'),
        },
        close: {
          class: 'closeWidget widgetDef',
          icon: require('@/assets/images/widgets/close.svg'),
        },
        suggestion: {
          class: 'suggestionWidget widgetDef',
          icon: require('@/assets/images/widgets/suggestion.svg'),
          text: this.$t('oneri'),
        },
        complaint: {
          class: 'complaintWidget widgetDef',
          icon: require('@/assets/images/widgets/complaint.svg'),
          text: this.$t('sikayet'),
        },
        cusService: {
          class: 'cusService widgetDef',
          icon: require('@/assets/images/widgets/cusService.svg'),
          text: this.$t('canlidestek'),
        },
      },
    }
  },
  methods: {
    changeIsOpen(bubble) {
      // bubble widgetin açılıp kapandığını parent componente bildiriyoruz

      if (bubble === 'close' || bubble === 'default') { this.$emit('changeIsOpen') } else { this.$emit('openChat', bubble) }
    },
  },

}
</script>
